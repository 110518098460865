import React from 'react'
import Navigation from "./navigation/navigation";

export default function Layout({ children }) {

  return (
    <>
      <div className="flex h-full">

        {/* Static sidebar for desktop */}
        <Navigation />

        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <main className="flex flex-1 overflow-hidden">
            {children}
          </main>
        </div>
      </div>
    </>
  )
}
